<template>
  <div>
    <notifications group="notification" position="bottom right" />
    <div v-if="brands.length > 0" class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b card-stretch">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title">
              <span class="card-label font-weight-bolder text-dark"
                >Brands</span
              >
            </h3>
            <div class="card-toolbar">
              <router-link
                to="/brands/new"
                class="btn btn-mc font-weight-bolder font-size-sm"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg
                    src="media/svg/icons/Communication/Add-user.svg"
                  />
                </span>
                Add New Brand
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div
                class="col-xl-3 col-lg-6 col-md-6 col-sm-6"
                v-for="(brand, key) in brands"
                :key="key"
              >
                <!--begin::Card-->
                <div class="card card-custom gutter-b card-stretch">
                  <!--begin::Body-->
                  <div class="card-body pt-4">
                    <!--begin::User-->
                    <div class="d-flex flex-column mb-7">
                      <!--begin::Pic-->
                      <!--                      <div class="flex-shrink-0 mr-4 mt-lg-0 mt-3">-->
                      <!--                        <div class="symbol symbol-circle symbol-lg-75">-->
                      <!--                          <img-->
                      <!--                            :src="-->
                      <!--                              appPath +-->
                      <!--                                '/media/cache/resolve/50x50/' +-->
                      <!--                                brand.featuredImage.src-->
                      <!--                            "-->
                      <!--                            :alt="brand.name"-->
                      <!--                          />-->
                      <!--                        </div>-->
                      <!--                        <div-->
                      <!--                          class="symbol symbol-lg-75 symbol-circle symbol-primary d-none"-->
                      <!--                        >-->
                      <!--                          <span class="font-size-h3 font-weight-boldest"-->
                      <!--                            >JM</span-->
                      <!--                          >-->
                      <!--                        </div>-->
                      <!--                      </div>-->
                      <!--end::Pic-->
                      <!--begin::Title-->
                      <div class="d-flex flex-column">
                        <router-link
                          :to="'/brands/edit/' + brand.id"
                          class="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0"
                          >{{ brand.name }}</router-link
                        >
                      </div>
                      <!--end::Title-->
                    </div>
                    <!--end::User-->
                    <!--begin::Desc-->
                    <p class="mb-7">{{ brand.description }}</p>
                    <!--end::Desc-->

                    <!--begin::Info-->
                    <!--                    <div class="mb-7">-->
                    <!--                      <div-->
                    <!--                        class="d-flex justify-content-between align-items-center"-->
                    <!--                      >-->
                    <!--                        <span class="text-dark-75 font-weight-bolder mr-2"-->
                    <!--                          >Email:</span-->
                    <!--                        >-->
                    <!--                        <a href="#" class="text-muted text-hover-primary"-->
                    <!--                          >luca@festudios.com</a-->
                    <!--                        >-->
                    <!--                      </div>-->
                    <!--                      <div-->
                    <!--                        class="d-flex justify-content-between align-items-cente my-1"-->
                    <!--                      >-->
                    <!--                        <span class="text-dark-75 font-weight-bolder mr-2"-->
                    <!--                          >Phone:</span-->
                    <!--                        >-->
                    <!--                        <a href="#" class="text-muted text-hover-primary"-->
                    <!--                          >44(76)34254578</a-->
                    <!--                        >-->
                    <!--                      </div>-->
                    <!--                      <div-->
                    <!--                        class="d-flex justify-content-between align-items-center"-->
                    <!--                      >-->
                    <!--                        <span class="text-dark-75 font-weight-bolder mr-2"-->
                    <!--                          >Location:</span-->
                    <!--                        >-->
                    <!--                        <span class="text-muted font-weight-bold"-->
                    <!--                          >Barcelona</span-->
                    <!--                        >-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--end::Info-->

                    <button class="btn btn-light-primary btn-block">
                      View products
                    </button>
                    <button
                      @click="removeBrand(brand.id)"
                      class="btn btn-light-danger btn-block"
                    >
                      Delete Brand
                    </button>
                  </div>
                  <!--end::Body-->
                </div>
                <!--end::Card-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-12 text-center">
        <b-spinner
          variant="primary"
          type="grow"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  name: 'ListBrand',
  data() {
    return {
      brands: [],
      view: {
        previous: null,
        first: '?page=1',
        last: null,
        next: null
      },
      appPath: process.env.VUE_APP_ROUTE_API_URL,
      isLoaded: false
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Brands', route: '/brands/list' }
    ])

    this.getBrands('/brands', this.view.first).then(({ data }) => {
      this.brands = [...data['hydra:member']]
      this.isLoaded = true
    })
  },
  methods: {
    async getBrands(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    async deleteBrand(id) {
      try {
        return await ApiService.delete('/brands/' + id)
      } catch (e) {
        throw new Error(e)
      }
    },
    removeBrand(id) {
      const confirmation = confirm('You want delete this brand?')
      if (confirmation === false) return

      this.deleteBrand(id).then(({ status }) => {
        if (status === 204) {
          this.brands = this.brands.filter(brand => brand.id !== id)
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Brand success deleted from database'
          })
        }
      })
    }
  }
}
</script>
